<template>
  <div id="graphPage">
    <div id="calendars">
      <calendar
        ref="calendar1"
        style="float: left; min-width: 350px; margin: 20px"
        :dateselected="dateselected1"
        v-on:clickComparison="clickComparison"
      ></calendar>
      <CalendarNoFooter
        ref="calendar2"
        v-show="showComparison"
        :pbyWeek="byWeek"
        :pbyDay="byDay"
        :pbyRange="byRange"
        :pbyMonth="byMonth"
        style="float:left;min-width: 350px;350px;margin:20px;"
        :dateselected="dateselected2"
      ></CalendarNoFooter>
    </div>
    <button @click="download" id="download_button">Download PDF</button>
    <div id="calendarContainer">
      <draggableCard
        :X="300"
        :Y="100"
        style="
          min-width: 120px;
          box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
          background: white;
          height: 210px;
        "
      >
        <template v-slot:main>
          <!-- The code below goes into the header slot -->
          <!-- The code below goes into the header slot -->

          <div style="display: flex; height: 150px">
            <div class="zones">
              <div class="styled-input-single">
                <input
                  type="checkbox"
                  id="check_all"
                  v-model="checked_all"
                  @click="clicked_select_all"
                /><label for="check_all">{{
                  checked_all ? "Unselect All" : "Select All"
                }}</label>
              </div>
              <div
                class="styled-input-single"
                v-for="(zone, key, index) in zone_ids"
                :key="index"
                @click="clicked"
              >
                <input
                  type="checkbox"
                  :id="key"
                  v-model="zone_ids[key].checked"
                  @click="clicked"
                />
                <label :for="key">{{ zone.label }}</label>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:footer> </template>
      </draggableCard>
    </div>
    <div id="pdf"></div>
    <div id="printablePage">
      <div id="graphContainer" @click="close_calendars">
        <polar-graph
          style="margin: 0 auto; width: 700px"
          :interval_ids="interval_ids"
          :zone_ids="zone_ids_for_graph"
          :values="values"
        ></polar-graph>

        <div>
          <ul id="pagination" :class="showComparison ? 'in_comparison' : ''">
            <li
              v-for="(page, key) in pages"
              :key="key"
              :class="
                (page.all ? 'all ' : '') +
                (page.comparison ? 'comparison' : '') +
                (page.is_active ? 'active' : '')
              "
              v-html="page.description"
              @click="activate_page(key)"
            ></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PolarGraph from "@/components/PolarGraph";
import Calendar from "@/components/calendarthree";
import CalendarNoFooter from "@/components/calendarthreewithoutfooter";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import DraggableCard from "@/components/draggableCard";
import moment from "moment";
export default {
  name: "graphPage",
  data() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      checked_all: true,
      pages: [],
      zone_ids: [{ label: 'Russia', checked: true, id:1 }, { label: 'PRC For', checked: true, id: 2 }],
      interval_ids: ["1m"],
      values: [],
      zone_ids_for_graph: [],
      authenticated: false,
      printing: false,
      showComparison: false,
      interceptor_id: 0,
      start_date_1: "2021-01-02",
      end_date_1: "2021-01-05",
      start_date_2: "2021-01-04",
      end_date_2: "2021-01-05",
      range1: {},
      range2: {},
      byWeek: false,
      byMonth: false,
      byRange: false,
      byDay: false,
    };
  },
  components: {
    DraggableCard,
    PolarGraph,
    Calendar,
    CalendarNoFooter,
  },
  mounted(){

    if (this.range1.startDate.getFullYear()!=1970 && this.range1.endDate.getFullYear()!=1970){
      var c1=this.$refs.calendar1;

  //    c1.byWeekbooleanValue = this.byWeek;
  //    c1.byWeek = this.byWeek;
  //    c1.byMonthbooleanValue = this.byMonth;
  //    c1.byMonth = this.byMonth;
  //    c1.byRangebooleanValue = this.byRange;
   //   c1.byRange = this.byRangebooleanValue;


   //   c1.byDaybooleanValue = this.byDay;
   //   c1.byDay = this.byDay;

      c1.setdateRange(this.range1.startDate,this.range1.endDate);



    }
    if (this.showComparison)
    if (this.range2.startDate.getFullYear()!=1970 && this.range2.endDate.getFullYear()!=1970){
      var c2=this.$refs.calendar2;
      c2.setdateRange(this.range2.startDate,this.range2.endDate);
    }
    setTimeout(() => this.$refs.calendar2.close(), 0);

  },
  async created() {
this.range1.startDate=new Date(localStorage.getItem("start_date_1"));
    this.range1.endDate=new Date(localStorage.getItem("end_date_1"));
    this.range2.startDate=new Date(localStorage.getItem("start_date_2"));
    this.range2.endDate=new Date(localStorage.getItem("end_date_2"));
this.showComparison=localStorage.getItem("comparison")=='true';


    this.byWeek= localStorage.getItem("byWeek")=='true';
    this.byMonth= localStorage.getItem("byMonth")=='true';
    this.byRange= localStorage.getItem("byRange")=='true';
    this.byDay= localStorage.getItem("byDay")=='true';





var t=this;

    this.getSettings(function () {

      if (t.showComparison){

  t.dateselected2()
}else{
  t.dateselected1()

}
    });


//    this.getEntries(start_date_1,end_date_1,start_date_2,end_date_2,comparison);


  },
  methods: {
    clicked_select_all() {
      var t = this;
      for (const [key] of Object.entries(this.zone_ids)) {
        t.zone_ids[key].checked = !t.checked_all;
      }
    },
    clickComparison(showComparison, byWeeks, byMonth, byDay, byRange) {
      this.showComparison = showComparison;
      this.byMonth = byMonth;
      this.byRange = byRange;
      this.byDay = byDay;
      this.byWeek = byWeeks;
      var c2 = this.$refs.calendar2.$refs.thiscalendar;
      c2.open = "inline";
    },
    clicked() {
      this.zone_ids_for_graph = this.zone_ids.slice();
    },
    clickOnRefresh() {
      //this.getEntries(this.start_date_1,this.end_date_1,this.start_date_2,this.end_date_2)
    },
    async getEntries(
      start_date_1 = "2021-01-02",
      end_date_1 = "2021-01-05",
      start_date_2 = "2021-01-04",
      end_date_2 = "2021-01-05",
      comparison = false
    ) {
      var t = this;



      let params = {};
      params["start_date_1"] = start_date_1;
      params["end_date_1"] = end_date_1;
      params["start_date_2"] = start_date_2;

      params["end_date_2"] = end_date_2;

      await Vue.prototype.$axios
        .get("entries", { params })
        .then(function (response) {
          t.values = [];
          var dataset = response.data.interval1;
          if (comparison)
            if (1 in response.data.difference) {
              dataset = response.data.difference;
            }

          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(dataset)) {
            var row = [];

            // eslint-disable-next-line no-unused-vars
            for (const [key1, value1] of Object.entries(value)) {

               row.push(value1);
            }

            t.values.push(row);
          }

        })
        .catch(function () {

          t.values = [];
        });
    },
    dateselected1(range1=null) {
if(range1!=null) {

  for (var k in range1) this.range1[k] = range1[k];
  if (typeof this.range2.startDate == "undefined") {
    for (k in range1) {
      var v = this.range1[k];
      this.range2[k] = v;
    }
  }

  localStorage.setItem("start_date_1",this.range1.startDate)
  localStorage.setItem("end_date_1",this.range1.endDate)
  localStorage.setItem("start_date_2",this.range1.startDate)
  localStorage.setItem("end_date_2",this.range1.endDate)
  localStorage.setItem("comparison",  this.showComparison)

  localStorage.setItem("byMonth",this.byMonth)
  localStorage.setItem("byRange",this.byRange)
  localStorage.setItem("byDay",this.byDay)
  localStorage.setItem("byWeek",this.byWeek)


}
      this.getEntries(
        formatDateValiStyle(this.range1.startDate),
        formatDateValiStyle(this.range1.endDate),
        formatDateValiStyle(this.range2.startDate),
        formatDateValiStyle(this.range2.endDate),
        this.showComparison
      );
      this.zone_ids_for_graph = this.zone_ids.slice();

      this.create_pagination();

    },
    dateselected2(range2=null) {

      if(range2!=null) {
        for (var k in range2) this.range2[k] = range2[k];
        if (typeof this.range1.startDate == "undefined") {
          for (k in this.range2) {
            var v = this.range2[k];
            this.range1[k] = v;
          }
        }
        localStorage.setItem("start_date_1", this.range1.startDate)
        localStorage.setItem("end_date_1", this.range1.endDate)
        localStorage.setItem("start_date_2", this.range2.startDate)
        localStorage.setItem("end_date_2", this.range2.endDate)
        localStorage.setItem("comparison", this.showComparison)

        localStorage.setItem("byMonth",this.byMonth)
        localStorage.setItem("byRange",this.byRange)
        localStorage.setItem("byDay",this.byDay)
        localStorage.setItem("byWeek",this.byWeek)

      }

      this.getEntries(
        formatDateValiStyle(this.range1.startDate),
        formatDateValiStyle(this.range1.endDate),
        formatDateValiStyle(this.range2.startDate),
        formatDateValiStyle(this.range2.endDate),
        this.showComparison
      );

      this.zone_ids_for_graph = this.zone_ids.slice();
      this.create_pagination();

      setTimeout(() => this.$refs.calendar1.close(), 500);
      setTimeout(() => this.$refs.calendar2.close(), 500);
    },
    async getSettings(f ) {
      var t = this;

      await Vue.prototype.$axios.get("settings").then(function (response) {
        t.zone_ids = [];
        for (const [key, value] of Object.entries(response.data.zone_ids)) {
          t.zone_ids[key - 1] = { label: value, checked: true, id: key };
        }
        for (const [key, value] of Object.entries(response.data.interval_ids)) {
          t.interval_ids[key - 1] = value;
        }

        if (f!=null) f();
      });

    },
    activate_page(page) {
      for (var i = 0; i < this.pages.length; i++) {
        this.pages[i].is_active = false;
      }
      this.pages[page].is_active = true;
      this.$forceUpdate();

      this.getEntries(
        formatDateValiStyle(this.pages[page].start_date_1),
        formatDateValiStyle(this.pages[page].end_date_1),
        formatDateValiStyle(this.pages[page].start_date_2),
        formatDateValiStyle(this.pages[page].end_date_2),
        this.pages[page].comparison
      );
    },
    create_pagination() {
      this.pages = [];
      if (this.showComparison) {
        (this.pages[0] = {
          description:
            moment(this.range1.startDate).format("DD MMM") +
            "-" +
            moment(this.range1.endDate).format("DD MMM"),
          comparison: false,
          all: false,
          start_date_1: this.range1.startDate,
          end_date_1: this.range1.endDate,
          start_date_2: this.range1.startDate,
          end_date_2: this.range1.endDate,
          is_active: false,
        }),
          (this.pages[1] = {
            description:
              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Comparison&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
            comparison: true,
            all: false,
            start_date_1: this.range1.startDate,
            end_date_1: this.range1.endDate,
            start_date_2: this.range2.startDate,
            end_date_2: this.range2.endDate,
            is_active: true,
          });
        this.pages[2] = {
          description:
            moment(this.range2.startDate).format("DD MMM") +
            "-" +
            moment(this.range2.endDate).format("DD MMM"),
          comparison: false,
          start_date_1: this.range2.startDate,
          end_date_1: this.range2.endDate,
          start_date_2: this.range2.startDate,
          end_date_2: this.range2.endDate,
          is_active: false,
          all: false,
        };
      } else {

        var currDate = moment(this.range1.startDate);

        this.pages.push({
          description: moment(currDate).format("DD MMM"),
          comparison: false,
          all: false,
          start_date_1: currDate.toDate(),
          end_date_1: currDate.toDate(),
          start_date_2: currDate.toDate(),
          end_date_2: currDate.toDate(),
          is_active: false,
        });
        while (currDate.add(1, "days").diff(moment(this.range1.endDate)) < 1) {
          this.pages.push({
            description: moment(currDate).format("DD MMM"),
            comparison: false,
            all: false,
            start_date_1: currDate.toDate(),
            end_date_1: currDate.toDate(),
            end_date_2: currDate.toDate(),
            start_date_2: currDate.toDate(),
          });
        }
        this.pages.push({
          description:
            moment(this.range1.startDate).format("DD MMM") +
            "-" +
            moment(this.range1.endDate).format("DD MMM"),
          comparison: false,
          all: true,
          start_date_1: this.range1.startDate,
          end_date_1: this.range1.endDate,
          start_date_2: this.range1.startDate,
          end_date_2: this.range1.endDate,
          is_active: true,
        });
      }
    },
    close_calendars() {
      var c1 = this.$refs.calendar1;
      c1.close();
      var c2 = this.$refs.calendar2;
      c2.close();
    },
    download() {
      this.printing = true;
      html2canvas(document.querySelector("#printablePage"), {
        imageTimeout: 5000,
        useCORS: true,
      }).then((canvas) => {
        const input = document.getElementById("printablePage");

        const divHeight = input.clientHeight;
        const divWidth = input.clientWidth;

        const ratio = divHeight / divWidth;

        document.getElementById("pdf").appendChild(canvas);
        let img = canvas.toDataURL("image/png");

        let pdf = new JsPDF("l", "pt", "A4");
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();

        height = ratio * width;

        pdf.addImage(img, "png", 0, 100, width, height);
        pdf.save("graph.pdf");
        document.getElementById("pdf").innerHTML = "";
        this.printing = false;
      });
    },
  },
};

function formatDateValiStyle(d) {
  return (
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1).toString()).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  );
}
</script>

<style scoped></style>
