<template>
  <div>
    <canvas
      id="c"
      width="700"
      height="700"
      style="transform: translateY(-50px); width: 700px; height: 700px"
    ></canvas>
  </div>
</template>

<script>
export default {
  name: "PolarGraph",
  data() {
    return {
      vueCanvas: Object,
      rectWidth: 100,
      canvas: Object,
      radius_multiplier: 35,
      zones: [],
    };
  },
  props: {
    interval_ids: Array,
    zone_ids: Array,
    values: Array,
  },
  watch: {
    interval_ids: {
      handler: function () {
        console.log("intervals changed");
        this.refresh();
      },
      deep: true,
    },
    zone_ids: {
      handler: function () {
        console.log("zone changed");
        console.log(this.zone_ids);

        console.log("zone changed");
        this.refresh();
      },
      deep: true,
    },
    values: {
      handler: function () {
        console.log("zone changed");
        this.refresh();
      },
      deep: true,
    },
  },
  methods: {
    refresh() {
      this.zones = this.zone_ids.filter(function (item) {
        return item.checked == true;
      });
      /// if no values show all zero?
      if (this.values.length == 0)
        this.values = [
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
        ];

      for (let i = 0; i < this.zones.length; i++) {
        if (typeof this.values[this.zones[i].id] == "undefined")
          this.values[this.zones[i].id] = [0, 0, 0, 0, 0, 0];
      }
      console.log(this.values.length);
      this.vueCanvas.clearRect(0, 0, this.canvas.width, this.canvas.height);

      if (this.values.length == 0) return;
      var total_values = this.zones.length;
      var total_levels = this.interval_ids.length;

      this.radius_multiplier = 220 / total_levels;
      this.drawGraphBackground(total_levels);

      this.drawCross();
      //draw values
      var maximum_value = 0;
      for (let lvl = 1; lvl <= total_levels; lvl++) {
        for (let idx = 1; idx <= total_values; idx++) {
          maximum_value = Math.max(
            Math.abs(this.values[this.zones[idx - 1].id - 1][lvl - 1]),
            maximum_value
          );
        }
      }

      for (var lvl = 1; lvl <= total_levels; lvl++) {
        for (var idx = 1; idx <= total_values; idx++) {
          var value = this.values[idx - 1][lvl - 1];
          value = this.values[this.zones[idx - 1].id - 1][lvl - 1];
          value = Math.abs(value);
          var alpha = (value / maximum_value) * 100 * 0.01;
          var textcolor =
            this.values[this.zones[idx - 1].id - 1][lvl - 1] != value
              ? "#FA951B"
              : "#2587C8"; //bubbles
          this.drawCircleOnGraph(
            textcolor,
            lvl,
            (idx / total_values) * 2 * Math.PI + 0.395,
            alpha,
            value,
            "10",
            textcolor
          );
        }
      }
      var offset = 0.395;
      for (idx = 1; idx <= total_values; idx++) {
        // draw column labels

        this.drawCircleOnGraph(
          "transparent",
          total_levels + 1,
          (idx / total_values) * 2 * Math.PI + offset,
          0,
          this.zones[idx - 1].label,
          "18"
        );
      }
      offset = this.get_offset(total_values);
      offset = 0;
      for (lvl = 1; lvl <= total_levels; lvl++) {
        for (idx = 1; idx <= 8; idx++) {
          // draw row column labels (8 is hardcoded as there are 8 axes)
          this.drawCircleOnGraph(
            "transparent",
            lvl,
            (idx / 8) * 2 * Math.PI + offset,
            1,
            this.interval_ids[lvl - 1],
            "9",
            "black"
          );
        }
      }

      //  this.drawText('aaa',550,50,0.25);
      //  this.drawText('BBB',50,550,-1.25);
      //
    },
    drawGraphBackground(layers = 7) {
      var ctx = this.vueCanvas;

      const X = this.canvas.width / 2;
      const Y = this.canvas.height / 2;
      ctx.globalAlpha = 0;
      var radius;

      ctx.globalAlpha = 1;
      for (var i = 2; i <= layers + 1; i++) {
        radius = i * this.radius_multiplier;
        ctx.lineWidth = this.radius_multiplier;
        ctx.beginPath();
        ctx.globalAlpha = ctx.globalAlpha - 0.12;
        ctx.strokeStyle = "#D7D7D7";
        ctx.arc(X, Y, radius, 0, 2 * Math.PI, false);
        ctx.stroke();
      }
    },
    drawText(text, x, y, rotation, text_size = "12", text_color = "#000000") {
      var ctx = this.vueCanvas;
      ctx.save();
      ctx.globalAlpha = 1;
      ctx.fillStyle = text_color;
      ctx.font = text_size + "px arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.translate(x, y);
      ctx.rotate(rotation);
      if (text_color == "#FA951B") text = "-" + text;
      ctx.fillText(text, 0, 0);

      ctx.restore();
    },
    drawCross() {
      var ctx = this.vueCanvas;
      ctx.save();
      ctx.setLineDash([5, 3]); /*dashes are 5px and spaces are 3px*/
      ctx.beginPath();
      ctx.moveTo(this.canvas.width / 2, 50);
      ctx.lineTo(this.canvas.width / 2, this.canvas.height - 50);
      ctx.moveTo(50, this.canvas.height / 2);
      ctx.lineTo(this.canvas.width - 50, this.canvas.height / 2);

      //
      ctx.moveTo(150, 150);
      ctx.lineTo(this.canvas.width - 150, this.canvas.height - 150);
      ctx.moveTo(150, this.canvas.width - 150);
      ctx.lineTo(this.canvas.width - 150, 150);

      //
      ctx.strokeStyle = "blue";
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.lineWidth = this.radius_multiplier;
      ctx.setLineDash([1, 1]); /*dashes are 5px and spaces are 3px*/
      ctx.globalAlpha = 1;

      var radius;

      ctx.restore();
      ctx.save();
      radius = this.radius_multiplier;
      ctx.lineWidth = this.radius_multiplier;
      ctx.beginPath();
      ctx.globalAlpha = 1;
      ctx.strokeStyle = "white";
      ctx.arc(
        this.canvas.width / 2,
        this.canvas.height / 2,
        radius / 2,
        0,
        2 * Math.PI,
        false
      );
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(
        this.canvas.width / 2,
        this.canvas.height / 2,
        radius,
        0,
        2 * Math.PI,
        false
      );
      ctx.stroke();

      ctx.restore();
    },
    drawCircleOnGraph(
      label = "",
      layer = 1,
      theta = 0,
      percent_value = 0,
      circle_label = "",
      font_size = "10",
      text_color = "#000000"
    ) {
      var level =
        this.radius_multiplier * (layer + 1) + this.radius_multiplier / 2;
      var x = level * Math.cos(theta) + this.canvas.width / 2;
      var y = level * Math.sin(theta) + this.canvas.height / 2;
      var ctx = this.vueCanvas;
      ctx.beginPath();
      var gradient = ctx.createRadialGradient(
        x,
        y,
        0,
        x,
        y,
        (this.radius_multiplier * Math.PI) / 2
      );
      gradient.addColorStop(0, label);
      if (percent_value > 0) {
        gradient.addColorStop(percent_value, "rgba(255, 255, 255, 0)");
      }
      gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
      ctx.strokeStyle = gradient;
      if (percent_value > 0) {
        ctx.arc(x, y, this.radius_multiplier / 2, 0, 2 * Math.PI);
        ctx.stroke();
      }
      this.drawText(
        circle_label == "" ? ~~(percent_value * 100) : circle_label,
        x,
        y,
        theta + 1.5,
        font_size,
        text_color
      );
    },
    drawCircle1() {
      var ctx = this.vueCanvas;
      var radgrad = ctx.createRadialGradient(60, 60, 0, 60, 60, 70);
      radgrad.addColorStop(0, "rgba(0,0,255,0)");
      radgrad.addColorStop(0.8, "rgba(228,0,0,1)");
      radgrad.addColorStop(1, "rgba(228,0,0,0)");

      // draw shape
      ctx.fillStyle = radgrad;

      ctx.fillRect(0, 0, 150, 150);

      radgrad = ctx.createRadialGradient(60, 60, 0, 60, 60, 80);
      radgrad.addColorStop(0, "rgba(255,255,255,0)");
      radgrad.addColorStop(0.8, "rgba(255,255,255,1)");
      radgrad.addColorStop(1, "rgba(0,0,228,0)");

      // draw shape
      ctx.fillStyle = radgrad;

      ctx.fillRect(0, 0, 150, 150);
    },
    get_offset(total_values) {
      var offset = 0;
      switch (total_values) {
        case 1:
          offset = -0.395 * 7;
          break;
        case 2:
          offset = 0.395 * 5;
          break;
        case 3:
          offset = 0.395 * 4;
          break;
        case 4:
          offset = 0.395 * 3;
          break;
        case 5:
          offset = 0.395 * 2.5;
          break;
        case 6:
          offset = 0.02;
          break;
        case 7:
          offset = 0.055;
          break;
        case 8:
          offset = 0;
          break;
      }
      return offset;
    },
  },
  mounted() {
    this.canvas = document.querySelector("#c");
    this.vueCanvas = document.getElementById("c").getContext("2d");
  },
};
</script>

<style scoped>
#c {
  margin: 0 auto;

  shape-rendering: auto;
}
</style>
