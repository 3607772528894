import Vue from "vue";
import VueRouter from "vue-router";
import uploadPage from "@/components/uploadPage";
import graphPage from "@/components/graphPage";
import sharePage from "@/components/sharePage";
Vue.use(VueRouter);

const routes = [
  {
    path: "/view",
    name: "view",
    component: graphPage
  },
  {
    path: "/upload",
    name: "upload",
    component: uploadPage
  },
  {
    path: "/share",
    name: "share",
    component: sharePage

},
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
