import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.version = process.env.VUE_APP_VERSION;
Vue.prototype.token = '';

Vue.prototype.$axios.defaults.headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};
Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_API;

new Vue({
  router,
  axios,
  render: (h) => h(App),
}).$mount("#app");
