<template>
  <div id="uploadPage">
    <div id="upload_header">
      <input
        id="csvfileupload"
        name="csvfileupload"
        @change="upload()"
        style="display: none"
        type="file"
        accept=".csv"
        ref="csvfileupload"
      />
      <button @click="showUpload" class="upload_buton">Upload</button>
    </div>
    <p v-html="server_response"></p>
    <div id="exceptions" v-bind:class="importing ? 'loading' : ''">
      <div
        id="exception"
        v-for="(exception, key) in exceptions"
        v-bind:key="key"
      >
        <p class="row">
          At line [<b>{{ exception.line }}</b
          >] column [<b>{{ exception.column }}</b
          >]Data:{{ exception.content }}
        </p>
        <p class="error">Error:{{ exception.exception }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "uploadPage",
  data() {
    return {
      importing: false,
      server_response:
        " Please select a file from your computer and click the [Upload] button",
      exceptions: [],
    };
  },
  methods: {
    showUpload() {
      if (this.$refs.csvfileupload.files[0] != "")
        this.$refs.csvfileupload.click();
    },
    async upload() {
      var t = this;
      t.importing = true;
      t.exceptions = [];
      t.server_response = "Uploading...";
      var file = this.$refs.csvfileupload.files[0];
      this.$refs.csvfileupload.value = "";
      if (typeof file === "undefined") {
        this.importing = false;
        return false;
      }
      let params = new FormData();

      params.append("csv", file);

      await Vue.prototype.$axios
        .post("entries", params)
        .then(function (response) {
          t.importing = false;
          console.log(response);
          t.server_response = response.data.message;
          t.exceptions = response.data.exceptions;
        })
        .catch(function (error) {
          t.importing = false;
          t.server_response = error.message;
          t.exceptions = error.exceptions;
        });
    },
  },
};
</script>

<style scoped type="scss">
#exceptions {
  margin: 0 auto;
  width: 90vw;
  text-align: left;
  overflow: auto;
  height: calc(100vh - 300px);
  display: block;
  font-family: Courier;
}
.upload_buton {
  background-color: #aaa;
  border-color: #aaa;
}
.upload_buton:hover {
  background-color: #2587c8;
  border-color: #2587c8;
}
.error {
  color: red;
}
.row b {
  color: blue;
}
</style>
