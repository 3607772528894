<template>
  <date-range-picker
    v-model="dateRange"
    ref="thiscalendar1"
    :locale-data="{ firstDay: 0, format: 'DD-MM-YYYY' }"
    @select="predateselected"
    @openclose="checkopen"
    :single-date-picker="range"
    :opens="opens"
    :ranges="customRanges"
    :show-week-numbers="true"
  >
    <!--    header slot-->

    <!--    input slot (new slot syntax)-->
    <template #input="picker" style="min-width: 350px">
      {{ picker.startDate.toLocaleDateString() }} -
      {{ picker.endDate.toLocaleDateString() }}
    </template>
    <!--    ranges (new slot syntax) -->
    <template #ranges="ranges">
      <div class="ranges" v-show="false">
        <ul>
          <li
            v-for="(range, name) in ranges.ranges"
            :key="name"
            @click="ranges.clickRange(range)"
          >
            <b>{{ name }} </b> <br /><small class="text-muted"
              >{{ range[0].toDateString() }} -
              {{ range[1].toDateString() }}</small
            >
          </li>
        </ul>
      </div>
    </template>
    <!--    footer slot-->
    <div slot="footer" class="slot" style="background: #2587c8">
      <div style="margin-left: auto">
        <input
          type="checkbox"
          :checked="byDaybooleanValue"
          @:input="byDaybooleanValue = $event.target.value"
          v-model="byDay"
          @change="clickByDay"
          class="btn btn-sm"
        />Day
      </div>

      <div style="margin-left: auto">
        <input
          type="checkbox"
          :checked="byWeekbooleanValue"
          @:input="byWeekbooleanValue = $event.target.value"
          v-model="byWeek"
          @change="clickByWeek"
          class="btn btn-sm"
        />Week
      </div>
      <div style="margin-left: auto">
        <input
          type="checkbox"
          :checked="byMonthbooleanValue"
          @:input="byMonthbooleanValue = $event.target.value"
          v-model="byMonth"
          @change="clickByMonth"
          class="btn btn-sm"
        />Month
      </div>
    </div>
  </date-range-picker>
</template>

<script>
import DateRangePicker from "../calendar/components/DateRangePicker.vue";

import "../assets/style.css";
import moment from "moment";
// localization is optional

export default {
  name: "Calendar",
  props: {
    always_show_calendars: Boolean,
    dateselected: Function,
  },
  data() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate());
    return {
      today: today,
      byWeekbooleanValue: false,
      byMonthbooleanValue: false,
      byRangebooleanValue: false,
      byDaybooleanValue: false,
      byDay: false,
      byRange: false,
      byWeek: false,
      byMonth: false,
      comparison: false,
      opens: "center",
      pickedBy: "",
      customRanges: {},
      normalcustomRanges: {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "This month": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth() + 1, 0),
        ],
        "This year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
      },
      byWeekscustomRanges: {},
      byMonthscustomRanges: {},
      range:false,
      dateRange: { startDate, endDate },
    };
  },
  components: {
    DateRangePicker,
  },
  methods: {
    clickByDay() {
      var t = this;
      t.$refs.thiscalendar1.removeSelect();
      this.dateRange.startDate = this.today;
      this.dateRange.endDate = this.today;
      this.$nextTick(() => {
        if (t.byDay) {
          t.byWeek = false;
          t.byRange = false;
          t.byMonth = false;
          t.comparison = true;
          t.$refs.thiscalendar1.singleDatePicker = true;
        } else {
          t.comparison = false;
          t.$refs.thiscalendar1.singleDatePicker = "range";
        }
        t.clickComparison();
      });
    },
    clickByWeek() {
      var t = this;
      t.$refs.thiscalendar1.removeSelect();
      this.dateRange.startDate = this.today;
      this.dateRange.endDate = this.today;
      this.$nextTick(() => {
        if (t.byWeek) {
          t.byRange = false;
          t.byMonth = false;
          t.byDay = false;
          t.comparison = true;
          t.$refs.thiscalendar1.singleDatePicker = true;
          t.$refs.thiscalendar1.in_selection = false;
        } else {
          t.comparison = false;
          t.$refs.thiscalendar1.singleDatePicker = "range";
        }

        t.clickComparison();
      });
    },

    clickByMonth() {
      var t = this;

      t.$refs.thiscalendar1.removeSelect();
      this.dateRange.startDate = this.today;
      this.dateRange.endDate = this.today;
      this.$nextTick(() => {
        if (t.byMonth) {
          t.byDay = false;
          t.byWeek = false;
          t.byRange = false;
          t.comparison = true;
          t.$refs.thiscalendar1.singleDatePicker = true;
          t.$refs.thiscalendar1.in_selection = false;
        } else {
          t.comparison = false;
          t.$refs.thiscalendar1.singleDatePicker = "range";
        }
        t.clickComparison();
      });
    },
    clickComparison() {
      this.byRange = !(this.byWeek & this.byDay & this.byMonth);
       // this.opens=(this.comparison?'inline':'center')
      this.$emit(
        "clickComparison",
        this.comparison,
        this.byWeek,
        this.byMonth,
        this.byDay,
        this.byRange
      );
    },
    checkopen(status) {
      if (status) this.clickComparison();
    },
    predateselected(range) {
      this.always_show_calendars = true;
      if (this.byWeek) {
        this.dateRange.startDate = moment(range.startDate)
          .startOf("week")
          .toDate();
        this.dateRange.endDate = moment(range.startDate).endOf("week").toDate();
      } else if (this.byMonth) {
        this.dateRange.startDate = moment(range.startDate)
          .startOf("month")
          .toDate();
        this.dateRange.endDate = moment(range.startDate)
          .endOf("month")
          .toDate();
      } else if (this.byRange) {
        this.dateRange.startDate = range.startDate;
        this.dateRange.endDate = range.endDate;
      } else if (this.byDay) {
        this.dateRange.startDate = range.startDate;
        this.dateRange.endDate = range.startDate;
      }

      this.dateselected(this.dateRange);
    },
    getDateRangeOfWeek(weekNo) {
      weekNo = weekNo + 1;
      var d1 = new Date();
      d1.setHours(0, 0, 0, 0);
      var d2 = new Date();
      d2.setHours(0, 0, 0, 0);
      var numOfdaysPastSinceLastMonday = eval(d1.getDay() - 1);
      d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
      //console.log(d1);
      var weekNoToday = d1.getWeek();
      var weeksInTheFuture = eval(weekNo - weekNoToday);

      d2.setDate(d1.getDate() + eval(7 * (weeksInTheFuture + 1)) - 1);
      d1.setDate(d1.getDate() + eval(7 * weeksInTheFuture));

      // var rangeIsFrom = eval(d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear();

      //  var rangeIsTo = eval(d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear() ;
      return [d1, d2];
    },
    getDateRangeOfMonths(monthNo) {
      var d1 = new Date();
      d1 = new Date(d1.getFullYear(), monthNo, 1);
      d1.setHours(0, 0, 0, 0);
      var d2 = new Date(d1.getFullYear(), monthNo + 1, 1);

      //  var rangeIsTo = eval(d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear() ;
      return [d1, d2];
    },

    close() {
      this.$refs.thiscalendar1.opens = "center";

      this.$refs.thiscalendar1.togglePicker(false);
    },
      setdateRange(startDate,endDate){
          this.dateRange.startDate=startDate;
          this.dateRange.endDate=endDate;
          console.log(this.dateRange)
      },
  },
  mounted() {
    this.customRanges = this.normalcustomRanges;
    for (let i = 1; i <= 52; i++) {
      var range = this.getDateRangeOfWeek(i);
      // console.log(range);
      this.byWeekscustomRanges[
        "Week " + (range[0].getWeek() - 1).toString()
      ] = range;
    }
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let i = 0; i < 12; i++) {
      this.byMonthscustomRanges[months[i]] = this.getDateRangeOfMonths(i);
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
Date.prototype.getWeek = function () {
  var onejan = new Date(this.getFullYear(), 0, 1);
  return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7);
};
</script>

<style scoped>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
.ranges {
  float: left;
  height: 241px;
  overflow-y: scroll;
}
</style>
