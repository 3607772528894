<template>
  <div id="app">
    <p v-if="token != null">Welcome {{ user.mail }}!</p>
    <p v-if="token == null">
      <!-- use router-link component for navigation. -->
      <!-- specify the link by passing the `to` prop. -->
      <!-- `<router-link>` will be rendered as an `<a>` tag by default -->
      invalid token [{{ token }}]
    </p>
    <p v-if="user.admin">
      <router-link :to="'/upload?token='+token" v-show="user.admin">Upload Data</router-link>
      <router-link :to="'/view?token='+token">View Data</router-link>
      <router-link :to="'/share?token='+token" v-show="user.admin"
        >Share with other users</router-link
      >
    </p>
    <!-- route outlet -->
    <!-- component matched by the route will render here -->
    <router-view v-if="token != null"> </router-view>
    <div id="version">v{{ version }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
require("@/assets/fonts/stylesheet.css");

Vue.use(VueRouter);

export default {
  name: "App",
  data() {
    return {
      version: Vue.prototype.version,
      user: {},
      token: "",
    };
  },
  components: {},
  async created() {
    this.token = this.$route.query.token;

    Vue.prototype.$axios.interceptors.request.use(
      (config) => {
        if (this.token) {
          config.headers["Authorization"] = `Bearer ${this.token}`;
          Vue.prototype.token=this.token;
        }


        return config;
      },

      (error) => {
        return Promise.reject(error);
      }
    );

    this.getSettings();
  },
  methods: {
    async getSettings() {
      var t = this;
      t.user = [];
      await Vue.prototype.$axios.get("settings").then(function (response) {
        t.user = response.data.user;
        if (!t.user.admin) t.$router.push({ path: 'view', query: { token: Vue.prototype.token }});
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Basier Square Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  text-align: center;
  color: #646464;
}
a {
  margin: 10px;
}

.styled-input-single {
  position: relative;
  padding-left: 15px;

  text-align: left;

  label {
    line-height: 18px;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      border-radius: 0;
    }
    &:before {
      left: 0;
      width: 10px;
      height: 10px;
      margin: -5px 0 0;

      background: #f7f7f7;
      box-shadow: 0 0 1px grey;
    }
    &:after {
      left: 2px;
      width: 6px;
      height: 6px;
      margin: -3px 0 0;
      opacity: 0;
      background: #79bc43;
      transform: translate3d(-40px, 0, 0) scale(0.5);
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;

    &:checked + label {
      &:before {
      }
      &:after {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}

.styled-input--square {
  label {
    &:before,
    &:after {
      border-radius: 0;
    }
  }
}

#version {
  position: fixed;
  bottom: 0px;
  margin: 0 auto;
}
</style>
