<template>
  <div ref="draggableContainer" id="draggable-container">
    <div id="draggable-header" @mousedown="dragMouseDown">Regions</div>
    <div class="main">
      <slot name="main"></slot>
    </div>
    <div class="card_footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "draggableCard",
  components: {},
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },
  props: {
    X: Number,
    Y: Number,
  },
  mounted: function () {
    this.$refs.draggableContainer.style.top = this.X + "px";
    this.$refs.draggableContainer.style.left = this.Y + "px";
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    dragMouseDown: function (event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};
</script>

<style>
#draggable-container {
  position: absolute;
  z-index: 9;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
#draggable-header {
  z-index: 10;

  width: 100%;

  font-size: 9px;
  margin: 0;
  padding: 0;
  letter-spacing: 0;
  text-align: center;

  line-height: 30px;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  font-size: 12px;

  height: 29px;

  background-repeat: no-repeat;
  background-color: #2587c8;
}
.close {
  position: absolute;
  right: 0;
  z-index: 100;
  transform: translate(10px, -10px);
  cursor: pointer;
}
.maximized .close {
  display: none;
}
.main {
  background: white;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.card_footer {
  background: #ffffff;
  /* border: 1px solid #D6D6D6; */
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
