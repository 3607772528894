<template>
  <div id="share">
    <div>
      <div v-for="(user, key, index) in users" :key="index" id="key">
        <input
          type="text"
          readonly
          v-model="user.mail"
          style="width: 10%; line-height: 18px; margin: 5px"
        />
        <input
          type="text"
          readonly
          v-bind:value="server + '?token=' + user.token"
          style="width: 40%; line-height: 18px; margin: 5px"
        />
        <button
          type="button"
          v-clipboard:copy="server + '?token=' + user.token"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          Copy
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
Vue.use(VueRouter);
export default {
  name: "sharePage",
  data() {
    return {
      server: "",
      users: [],
    };
  },
  async created() {
    this.server = window.location.origin;
    this.getSettings();
  },
  methods: {
    async getSettings() {
      var t = this;

      await Vue.prototype.$axios.get("settings").then(function (response) {
        t.users = [];
        for (const [key, value] of Object.entries(response.data.users)) {
          t.users[key] = value;
        }
      });
    },
    onCopy: function () {
      alert("Link was copied. You can now paste it in a email message");
    },
    onError: function () {
      alert("Failed to copy texts");
    },
  },
};
</script>

<style scoped></style>
